<template>
  <el-card class="v_store_recharge_list">
    <div class="g-flex"  slot="header">
      <span>商家充值列表</span>
      <div class="g-flex-justify-end g-flex-1">
          <el-button size="mini" type="success" @click="exportClick">导出列表</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="状态:">
        <el-select v-model="query.status" @change="getList()">
          <el-option label="全部" value="ALL"></el-option>
          <el-option label="审核未通过" value="0"></el-option>
          <el-option label="审核中" value="1"></el-option>
          <el-option label="审核通过" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="时间:">
        <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                        :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-select slot="label" v-model="query.search_key" placeholder="查询">
          <el-option label="商家ID" value="user_id"></el-option>
          <el-option label="订单ID" value="id"></el-option>
          <el-option label="手机号" value="mobile"></el-option>
        </el-select>
        <el-row>
          <el-col :span="14">
            <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="info" @click="resetData">重置</el-button>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" @click="getList()">查询</el-button>
          </el-col>

        </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <!-- <el-table-column prop="ent_id" label="企业ID" width="80" :show-overflow-tooltip="true" v-if="$global.adminUser.type === 4"/> -->
      <el-table-column prop="id" label="充值订单ID" width="100" :show-overflow-tooltip="true"/>
      <el-table-column prop="user_id" label="商家ID" width="140" :show-overflow-tooltip="true" v-if="$global.adminUser.type != 2"/>
      <el-table-column label="手机号" :show-overflow-tooltip="true"  width="100" v-if="$global.adminUser.type != 2">
        <template slot-scope="scope">
          <span>{{scope.row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column label="开户姓名" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.money}}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值银行" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.bank_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值卡号" width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{scope.row.card_number}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{formatDate(scope.row.modify_time, 'yyyy-MM-dd hh:mm')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="g-red">审核未通过</span>
            <span v-else-if="scope.row.status==1" class="g-blue">审核中</span>
            <span v-else-if="scope.row.status==2" class="g-green">充值成功</span>
          </template>
      </el-table-column>
        <el-table-column label="备注" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{scope.row.remark ? scope.row.remark: '无' }}</span>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right" v-if="auth.pass || auth.nopass">
        <template slot-scope="scope" v-if="scope.row.status==1">
          <el-button size="mini" type="primary"  v-if="auth.pass" @click="apiRechargeSuccessHandel(scope.row)">审核通过</el-button>
          <el-button size="mini" type="danger"  v-if="auth.nopass" @click="apiRechargeFailHandel(scope.row)">审核不通过</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <money-store-recharge-list-export-pop ref="MoneyStoreRechargeListExportPop"/>
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetRechargeList, apiRechargeSuccess, apiRechargeFail } from '@/utils/api.js'
  import MoneyStoreRechargeListExportPop from './MoneyStoreRechargeListExportPop.vue'
  export default {
    components: { MoneyStoreRechargeListExportPop },
    data() {
      return {
        auth: {
          pass: this.$global.ApiList.includes(40),
          nopass: this.$global.ApiList.includes(41),
        },

        query: { date_time: [], search_key: 'mobile',search_val: '', page:1, limit: 15, status: 'ALL' },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {
          remark: '',
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      formatDate,

      // 点击导出
      exportClick() {
        this.$refs.MoneyStoreRechargeListExportPop.onShow()
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetRechargeList(this.query)
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
        if(!success) return
      },

      // 充值审核通过
      async apiRechargeSuccessHandel(item) {
         this.$confirm('此操作将该条充值记录审核通过, 是否继续?', '充值审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await apiRechargeSuccess({ id: item.id })
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(()=>{})
      },

      // 充值审核不通过
      async apiRechargeFailHandel(item) {
        this.$prompt('请输入充值审核不通过理由：', '充值审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern:  /\S/,
          inputErrorMessage: '理由不能为空'
        }).then(async ({ value }) => {
          this.table.loading = true
          const { success, data } = await apiRechargeFail(Object.assign({}, item, { remark: value })).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(() => {});
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },


      // 点击保存
      async save() {
        if(!this.formData.ip) return this.$message.error('ip地址不能为空')
        const loading = this.$loading()
        const { success, data } = await (this.formData).catch(() => loading.close())
        loading.close()
        if(!success) return
        this.$message.success(data.msg)
        this.form = false
        this.getList()
      },

      // 点击删除
       del({ id }) {
        this.$confirm('将该用户永久移出黑名单, 是否继续?', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } = await ({ id: id })
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList()
        }).catch(()=>{})
      }, 

      resetData() {
        this.query = { date_time: [], search_key: 'mobile',search_val: '', page:1, limit: 15, status: 'ALL' }
        this.getList()
      }
    }
  }
</script>

<style lang="scss">
</style>