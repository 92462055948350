<template>
 <div class="s_user_cash_out_export_pop">
    <el-dialog :title="'导出商家充值列表'" class="s-user-cash-out-dialog" top="15vh" width="800px" :close-on-click-modal="false" :visible.sync="show">
      <el-form :model="formData" label-width="140px" size="mini">
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出充值状态：">
                <el-select v-model="formData.status" placeholder="请选择">
                    <el-option label="全部" value="ALL"></el-option>
                    <el-option label="审核未通过" value="0"></el-option>
                    <el-option label="审核中" value="1"></el-option>
                    <el-option label="审核通过" value="2"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出日期范围：">
                <el-date-picker v-model="formData.date_time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出商家id：">
                <el-input v-model="formData.user_id" placeholder="请输入导出用户id"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" >取 消</el-button>
        <el-button type="primary" @click="apiExportCashHandel">确定导出</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { apiExportRecharge } from '@/utils/api.js'
export default {
  data() {
    return {
      show: false,
      value1: [],
      formData: {
        user_id: '',
        status: 'ALL',
        date_time: []
      },
    }
  },
  methods: {
    onShow() {
      console.log(this.formData)
      this.show = true
    },
    onClose() {
      this.show = false
    },
    // 确认导出
    async apiExportCashHandel() {
      let loading = this.$loading()
      const { success, data } = await apiExportRecharge(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      let a = document.createElement('a')
      a.setAttribute('href', data.filePath)
      a.setAttribute('download', "")
      a.click()
      console.log(data)
    },
  }
}
</script>

<style lang='scss'>

</style>